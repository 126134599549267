.complete-card {
  background: $grey-100;
  border-radius: 0.75rem;
}

.complete-card--type-1-v1,
.complete-card--type-1-v2,
.complete-card--type-1-v3 {
  padding: 1.5rem;
  max-width: 893px;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: toRem(8);

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    max-width: none;
  }

  .complete-card__balance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 343px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: none;
    }

    .complete-card__balance-head {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include media-breakpoint-down(lg) {
        gap: 1.125rem;
        margin-bottom: 1rem;
      }

      .complete-card__balance-head__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .complete-card__balance-head__title-text {
          color: $white;

          @include media-breakpoint-down(lg) {
            font-size: 1.125rem;
            line-height: 1.2;
          }
        }

        .complete-card__balance-head__title-influsion {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .complete-card__balance-head__title-influsion-icon {
            width: auto;
            height: auto;
            max-width: 1.25rem;
            max-height: 1.25rem;
          }

          .complete-card__balance-head__title-influsion-value {
            &.text-bullish {
              color: $green;
            }
            &.text-bearish {
              color: $red;
            }
          }
        }
      }
      .complete-card__balance-head__value {
        font-family: $font-family-base;
        font-size: 2.4375rem;
        font-weight: 600;
        line-height: 1.2;
        color: $white;

        @include media-breakpoint-down(lg) {
          font-weight: 500;
        }

        @include media-breakpoint-down(xs-1) {
          font-size: toRem(32);
        }
      }
    }

    .complete-card__balance-revenue {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: toRem(8);

      @include media-breakpoint-down(xs-1) {
        flex-direction: column;
        gap: 0.25rem;
      }

      .complete-card__balance-revenue__income,
      .complete-card__balance-revenue__expenses {
        .balance-revenue__income-title,
        .balance-revenue__expenses-title {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;

          .balance-revenue__income-title-icon,
          .balance-revenue__expenses-title-icon {
            max-width: 1.5rem;
            max-height: 1.5rem;
            background: #252D55;
            padding: 0.1875rem;
            border-radius: 50%;
            transform: rotate(180deg);
          }

          .balance-revenue__income-title-text,
          .balance-revenue__expenses-title-text {
            color: $grey-40;
          }
        }
      }

      .complete-card__balance-revenue__separator {
        width: 1px;
        background: $grey-80;
      }
    }
  }

  .complete-card__separator {
    width: 1px;
    background: $grey-80;

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 1px;
      margin: 1.5rem 0;
    }
  }

  .complete-card__wallet {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 411px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      gap: 1.5rem;
      max-width: none;
    }

    .complete-card__wallet-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .complete-card__wallet-head__title-text {
        color: $white;

        @include media-breakpoint-down(lg) {
          font-size: 1.125rem;
          line-height: 1.2;
        }
      }

      .complete-card__wallet-head__title-total-currencies {
        color: $grey-40;
      }
    }

    .complete-card__wallet-data {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 1.5rem;
      }

      .complete-card__wallet-data__chart {
        position: relative;
        
        .complete-card__wallet-data__donut-chart-render {
          transform: scale(1.2);
        }

        .complete-card__wallet-data__donut-chart-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background: $grey-80;

          position: absolute;
          left: 51%; top: 50%;
          transform: translate(-50%, -50%);
        }

        .complete-card__wallet-data__chart-infusion {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          position: absolute;
          left: 0; bottom: 0;
        }
      }

      .complete-card__wallet-data__currencies {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        .wallet-data__currency {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;

          max-width: 178px;
          width: 178px;

          @include media-breakpoint-down(lg) {
            max-width: none;
            width: 100%;
          }

          .wallet-data__currency-icon {
            width: auto;
            height: auto;
            max-width: 1.25rem;
            max-height: 1.25rem;
          }

          .wallet-data__currency-text {
            .wallet-data__currency-text-acronym {
              color: $white;
              margin-bottom: 0.125rem;
            }

            .wallet-data__currency-text-name {
              color: $grey-60;
            }
          }

          .wallet-data__currency-influsion {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            margin-left: auto;

            .wallet-data__currency-influsion-icon {
              width: auto;
              height: auto;
              max-width: 1rem;
              max-height: 1rem;
            }

            .wallet-data__currency-influsion-value {
              font-weight: 500;
              color: $green;
            }
          }
        }
      }
    }
  }
}

.complete-card--type-1-v2 {
  @include media-breakpoint-down(lg) {
    .complete-card__balance {
      order: -1;
    }
  
    .complete-card__wallet {
      order: 3;
    }
  }
}

.complete-card--type-1-v3 {
  flex-direction: column;
  max-width: fit-content;
  gap: 0;

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  .complete-card__balance {
    margin-bottom: 1.75rem;
    max-width: none;

    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }
    .complete-card__balance-head {
      margin-bottom: 1.5rem;
    }
  }

  .complete-card__wallet {
    .complete-card__wallet-data {
      gap: 1.1875rem;
    }
  }

  .complete-card__separator {
    margin: 1.5rem 0;
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  .complete-card__exchange {
    margin-top: 1.9375rem;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
    .advance-card__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      @include media-breakpoint-down(xs-2) {
        margin-bottom: 1.5rem;
      }
    }
  
    .advance-card__price {
      align-items: center;
      margin-bottom: 1.5rem;
      font-family: $font-family-secondary;
      display: none;
      
      @include media-breakpoint-down(lg) {
        display: flex;
      }
  
      @include media-breakpoint-down(xs-2) {
        margin-bottom: 1rem;
      }
  
      .advance-card__price-crypto {
        display: flex;
        align-items: center;
        gap: 0.5625rem;
  
        @include media-breakpoint-down(xs-2) {
          gap: 0.5rem;
        }
  
        .advance-card__price-crypto-value {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.6875rem;
          color: $white;
  
          @include media-breakpoint-down(xs-2) {
            font-size: 1.125rem;
          }
        }
  
        .advance-card__price-crypto-name {
          font-size: 1rem;
          font-weight: 400;
          color: $grey-40;
  
          @include media-breakpoint-down(xs-2) {
            font-size: 0.875rem;
          }
        }
      }
  
      .advance-card__price-arrow {
        margin: 0 1rem;
      }
  
      .advance-card__price-currency {
        display: flex;
        align-items: center;
        gap: 0.875rem;
  
        @include media-breakpoint-down(xs-2) {
          gap: 0.5rem;
        }
  
        .advance-card__price-currency-value {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.6875rem;
          color: $white;
  
          @include media-breakpoint-down(xs-2) {
            font-size: 1.125rem;
          }
        }
  
        .advance-card__price-currency-name {
          font-size: 1rem;
          font-weight: 400;
          color: $grey-40;
  
          @include media-breakpoint-down(xs-2) {
            font-size: 0.875rem;
          }
        }
      }
    }
  
    .advance-card__forms-currency {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .advance-card__forms-currency__title {
        font-family: $font-family-secondary;
        font-size: 1rem;
        font-weight: 400;
        color: $grey-60;
        margin-bottom: 0.5rem;
      }
  
      .forms-purchase--advance-card {
        background: none !important;
  
        @include media-breakpoint-down(lg) {
          display: none;
        }

        .forms-group--purchase {
          .forms-purchase__purchase {
            width: auto;
          }
        }
      }

      .forms-currency--advance-card {
        display: none;

        @include media-breakpoint-down(lg) {
          display: flex;
        }

        .forms-group--currency {
          width: unset !important; 
          
          .forms-group__dropdown {
            gap: 1rem;
          }
        }
      }
    }
  
    .advance-card__button {
      margin-top: 1.1875rem;
      width: 100%;
  
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
        padding: 1rem 0;
      }
    }
  }
}

.complete-card--type-2-v1,
.complete-card--type-2-v2,
.complete-card--type-3,
.complete-card--type-4 {
  max-width: 1008px;
  padding: 1.5rem;

  .complete-card__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;

    .complete-card__head-title{
      min-width: max-content;
      margin-right: 1.5rem;
    }

    .complete-card__head-tabs {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: max-content;
      overflow-y: hidden;
      overflow-x: auto;

      list-style: none;
      margin: 0;
      padding: 0;
      
      //hiding scrollbar
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .complete-card__head-tab {
        cursor: pointer;
        min-width: max-content;

        &:hover {
          .complete-card__head-tab-text {
            color: $white;
          }
        }

        .complete-card__head-tab-text {
          font-family: $font-family-base;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.6;
          color: $grey-60;
          transition: all linear .2s;

          padding: 0.5rem 1rem;
          border-radius: 3.125rem;

          &.active {
            background: $darker-grey;
            color: $white;
          }
        }
      }
    }

    .complete-card__dropdown {
      display: none;
      
      .btn {
        padding: 0.5rem 1rem;
        background: transparent;
      }

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  .complete-card__chart-wrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;

    //hiding scrollbar
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }

    .complete-card__chart-render {
      @include media-breakpoint-down(lg) {
        min-width: 626px;
      }
    }
    
    .complete-card__chart-area-vignette--left {
      position: absolute;
      width: 167px;
      height: 314px;
      top: 0;
      left: 2.875rem;
      background: linear-gradient(90deg, $grey-100 0%, rgba(33, 36, 50, 0) 100%);
      pointer-events: none;
    }

    .complete-card__chart-area-vignette--top {
      position: absolute;
      width: 95%;
      height: 102px;
      top: 0;
      left: 2.25rem;
      background: linear-gradient(180deg, $grey-100 0%, rgba(33, 36, 50, 0) 100%);
      pointer-events: none;
    }

    .complete-card__chart-area-vignette--right {
      position: absolute;
      width: 167px;
      height: 314px;
      top: 0; right: 0;
      background: linear-gradient(270deg, $grey-100 0%, rgba(33, 36, 50, 0) 100%);
      pointer-events: none;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

.complete-card--type-3,
.complete-card--type-4 {
  .complete-card__head {
    justify-content: flex-start;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 1.5rem;
    }

    .complete-card__head-title-wrapper {
      @include media-breakpoint-down(lg) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .complete-card__head-tabs {
      display: none;
      width: 100%;

      @include media-breakpoint-down(lg) {
        display: flex;
      }

      .complete-card__head-tab {
        width: 100%;
        text-align: center;

        .complete-card__head-tab-text {
          width: 100%;
          background: none;
          border: none;

          &.active {
            background: $darker-grey;
          }
        }

      }
    }

    .complete-card__head-values {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.5rem;
      margin-left: 1.5rem;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
      }

      @include media-breakpoint-down(xs-1) {
        grid-template-columns: 1fr;
      }

      .complete-card__head-menu {
        color: $grey-60;
        
        .complete-card__head-value {
          margin-left: 0.1875rem;
          color: $green;
        }
      }
    }

    .complete-card__head-dropdown {
      display: flex;
      gap: 1rem;
      margin-left: auto;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .complete-card__dropdown {
        display: block;

        .btn {
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  .complete-card__chart-wrapper {

    .complete-card__chart-candlestick-column-bar {
      position: absolute;
      bottom: 3rem;
      opacity: 0.8;
      width: 100%;
    }

    .complete-card__chart-area-vignette--left {
      left: 0;
      pointer-events: none;
    }
  }

  .tab-content {
    &--desktop {
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }
}